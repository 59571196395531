import React from "react";

const skillsContent = [
  { skillClass: "p100", skillPercent: "100", skillName: "ARCHITECTURE" },
  { skillClass: "p98", skillPercent: "98", skillName: "LANDSCAPING" },
  { skillClass: "p96", skillPercent: "96", skillName: "DESIGN" },
  { skillClass: "p93", skillPercent: "93", skillName: "FASHION" },
  { skillClass: "p97", skillPercent: "97", skillName: "INTERIOR DESIGN" },
  { skillClass: "p94", skillPercent: "94", skillName: "RESTORATION" },
  { skillClass: "p90", skillPercent: "90", skillName: "PAINT" },
  { skillClass: "p91", skillPercent: "91", skillName: "SCULTURE" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
