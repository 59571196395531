import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Jalisco</span>La Manzanilla de la Paz ,
        México.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:mikkael@kreisindustries.com">Mikkael@kreisindustries.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">WhatsApp</span>{" "}
        <a href="https://api.whatsapp.com/send?phone=5213312822490">+52 1 33 1282 2490</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
