import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Mikkael" },
  { meta: "last name", metaInfo: "Kreis" },
  { meta: "Age", metaInfo: "N/A Years" },
  { meta: "Nationality", metaInfo: "World" },
  { meta: "Architec/Designer", metaInfo: "Available" },
  { meta: "Address", metaInfo: "Jalisco" },
  { meta: "phone", metaInfo: "+523312822490" },
  { meta: "Email", metaInfo: "Mikkael@kreisindustries.com" },
  // { meta: "Skype", metaInfo: " steve.milner" },
  { meta: "langages", metaInfo: "Spanish, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
