import React from "react";

const educationContent = [
  {
    year: "A long time ago...",
    degree: "ARCHITECTURE",
    institute: "ITESO",
    // details: `  Lorem ipsum dolor sit amet, consectetur tempor incididunt ut labore
    //     adipisicing elit`,
  },
  {
    year: "1998",
    degree: "PERMACULTURE",
    institute: "PERMACULTURE INSTITUTE OF AUSTRALIA",
    // details: `Lorem incididunt dolor sit amet, consectetur eiusmod dunt doldunt dol
    //     elit, tempor incididunt`,
  },
  {
    year: "2000",
    degree: "Restaurationofens and monuments historicos ",
    institute: "UGTO",
    // details: `Lorem ipsum dolor sit amet, tempor incididunt ut laboreconsectetur
    //     elit, sed do eiusmod tempor duntt`,
  },
  {
    year: "2001",
    degree: "INTERIOR DESIGN",
    institute: "UAG",
    // details: `Lorem ipsum dolor sit amet, tempor incididunt ut laboreconsectetur
    //     elit, sed do eiusmod tempor duntt`,
  }
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
